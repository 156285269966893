<template>
    <div class="box">
        <img
            src="https://middle-ground.shulanhealth.com/F42235_203221104104344.png"
            alt=""
            class="bg-title"
        />
        <div class="titles">闲置物品发布</div>
        <div class="box-margin">
            <div class="content-box">
                <div class="box-item" style="height:120px;">
                    <div>物品名称 <span style="color: red">*</span></div>
                    <div>
                        <textarea
                            type="text"
                            class="input-gh"
                            style="resize: none;height: 63px;"
                            v-model="goodsName"
                            placeholder="请输入"
                        />
                    </div>
                </div>
                <div class="box-item">
                    <div>物品价格 <span style="color: red">*</span></div>
                    <div>
                      <input
                            type="text"
                            class="input-gh"
                            v-model="goodsPrice"
                            maxlength="11"
                            placeholder="请输入"
                        />
                    </div>
                </div>
                <div class="box-item">
                    <div>物品数量 <span style="color: red">*</span></div>
                    <div>
                      <input
                            type="text"
                            class="input-gh"
                            v-model="goodsNum"
                            maxlength="11"
                            placeholder="请输入"
                        />
                    </div>
                </div>
                <div class="box-item3">
                    <div>计量单位 <span style="color: red">*</span></div>
                    <div style="margin-top:10px;">
                      <van-radio-group v-model="goodsUnit" direction="horizontal">
                          <van-radio style="margin-bottom:10px;" name="斤">斤</van-radio>
                          <van-radio style="margin-bottom:10px;" name="克(g)">克(g)</van-radio>
                          <van-radio style="margin-bottom:10px;" name="公斤(kg)">公斤(kg)</van-radio>
                          <van-radio style="margin-bottom:10px;" name="个">个</van-radio>
                          <van-radio style="margin-bottom:10px;" name="袋">袋</van-radio>
                          <van-radio style="margin-bottom:10px;" name="瓶">瓶</van-radio>
                      </van-radio-group>
                    </div>
                </div>
                <div class="box-item3">
                    <div>分类 <span style="color: red">*</span></div>
                    <div >
                      <van-radio-group v-model="goodsCategory" direction="horizontal">
                          <van-radio style="margin-bottom:10px;" name="食品">食品</van-radio>
                          <van-radio style="margin-bottom:10px;" name="百货">百货</van-radio>
                          <van-radio style="margin-bottom:10px;" name="生活用品">生活用品</van-radio>
                          <van-radio style="margin-bottom:10px;" name="电器">电器</van-radio>
                          <van-radio style="margin-bottom:10px;" name="建筑用品">建筑用品</van-radio>
                          <van-radio style="margin-bottom:10px;" name="服饰">服饰</van-radio>
                          <van-radio style="margin-bottom:10px;" name="其他">其他</van-radio>
                      </van-radio-group>
                    </div>
                </div>
                <div class="box-item3">
                      <div>上传物品图片<span style="color: red">*</span></div>
                      <div>
                          <div
                              class="x-box1"
                              style="display: flex; flex-wrap: wrap; width: 100%"
                          >
                              <div
                                  style="
                                      display: flex;
                                      align-items: center;
                                      flex-wrap: wrap;
                                      width: 100%;
                                  "
                              >
                                  <div
                                      class="img-list"
                                      v-for="(item, index) in jyjcbgList"
                                      :key="index"
                                  >
                                      <img
                                          style="
                                              width: 100%;
                                              height: 77px;
                                              border-radius: 10px;
                                          "
                                          :src="item"
                                          @click="showPic(index)"
                                      />
                                      <img
                                          src="https://static.shulanhealth.com/del-pic-icon.png"
                                          class="dele"
                                          @click="delPic(index)"
                                      />
                                  </div>
                                  <div
                                      class="add-img1"
                                      v-if="jyjcbgList.length < 8"
                                  >
                                      <a
                                          href="javascript:;"
                                          class="file"
                                          style="
                                              height: 77px;
                                              cursor: pointer;
                                              border-radius: 10px;
                                          "
                                      >
                                          <div style="text-align: center">
                                              <img
                                                  class="img-size1"
                                                  src="http://middle-ground.shulanhealth.com/add-img.png"
                                              />
                                          </div>
                                          <input
                                              style="height: 77px"
                                              type="file"
                                              name=""
                                              accept="image/*"
                                              id=""
                                              @change="upLoadImg($event)"
                                          />
                                      </a>
                                  </div>
                              </div>
                          </div>
                      </div>
                </div>
                <!-- <div class="box-item2">
                    <div style="font-size:16px;">备注 <span style="color: red"></span></div>
                    <div>
                        <textarea
                            type="text"
                            class="input-xq"
                            v-model="remarks"
                            placeholder="请输入"
                        />
                    </div>
                </div> -->
                <div class="box-item4">
                    <div>免责声明：</div>
                    <div>请您认真阅读并充分理解以下信息：</div>
                    <div>①本平台只提供信息共享服务且完全免费，所涉及的费用需线下自行支付</div>
                    <div>
                        ②用户不发布虚假信息
                    </div>
                    <div>
                        ③买卖双方所产生的一切纠纷概与本平台无关
                    </div>
                </div>
                <div class="box-item" style="height:55px;">
                  <van-checkbox v-model="iAgree" shape="square" icon-size="16px" style="font-size:12px;">请您认真阅读并充分理解上述信息,同意请勾选</van-checkbox>
                </div>
                <div
                      v-if="iAgree && !isLoading"
                    class="share-url"
                    @click="submitOrEditData"
                >
                   确定
                </div>
                <div
                      v-else
                    class="share-url1"
                >
                   确定
                </div>
                <!-- <div class="share-url" v-if="isLoading">正在提交...</div> -->
            </div>
            <div style="height: 15px"></div>
        </div>
        <div class="loading" v-show="isLoading">
            <van-loading text-color="#1DA9A4" color="#1DA9A4" vertical
                >上传中...</van-loading
            >
        </div>
    </div>
  </template>
  <script>
    import { goods } from "@/api";
    import { passenger } from "@/api";
  import Config from "@/config";
  // import draggable from "vuedraggable";
  import * as imageConversion from 'image-conversion'
  import {
    ImagePreview,
    Loading,
    Toast,
    Dialog,
    Checkbox,
    RadioGroup, Radio
  } from "vant";
  // import { compressImage } from "@/utils/CompressImageUtils";
  export default {
    components: {
      // draggable,
        VanLoading: Loading,
        VanCheckbox: Checkbox,
        VanRadioGroup: RadioGroup,
        VanRadio: Radio,
    },
    data() {
        return {
            goodsName: '',
            goodsPrice: '',
            goodsNum: '',
            goodsPic: '',
            goodsUnit: '斤',
            cName: '',
            cPic: '',
            cPrice: '',
            goodsCategory: '食品',
            showLxgh: false,
            iAgree:false,
            passengerName: "",
            passengerMobile: "",
            passengerNum: "",
            fee: "",
            remarks: "",
            tripDate: "",
            tripTime: "",
            carpooling: 1,
            showDialog: false,
            showCalendar: false,
            showMapPopup:false,
            showMapTjdPopup: false,
            qwrqText: "",
            minDate: "",
            maxDate: "",
            columns: [
                "05:00-05:30",
                "05:30-06:00",
                "06:30-07:00",
                "07:30-08:00",
                "08:30-09:00",
                "09:30-10:00",
                "10:30-11:00",
                "11:30-12:00",
                "12:30-13:00",
                "13:30-14:00",
                "14:30-15:00",
                "15:30-16:00",
                "16:30-17:00",
                "17:30-18:00",
                "18:30-19:00",
                "19:30-20:00",
                "20:30-21:00",
                "21:30-22:00"
            ],
            showQwrqPopup: false,
            status: "",
            isSel: 2,
            isVip: true,
            hisId: "",
            isLoading: false,
            sex: 1, // 性别 1男2女
            realName: "", // 真实名称
            idCardNumber: "", // 证件号码
            mobile: "", // 联系电话
            age: "", // 年龄
            jzxq: 1, // 就诊需求
            zjlx: "", // 证件类型
            zjlxObj: {}, // 证件类型
            qwyy: "", // 期望医院
            qwyyObj: {}, // 期望医院
            zjlxCom: [],
            jzyyCom: [],
            slhCom: {},
            jyjcbgList: [],
            jyjcbgStringList: [],
            showZjlxPopup: false,
            showQwyyPopup: false,
            qywxId: "",
            failEdit: true, //是否可以填写
            isCurrentTime: false,
            keyword: "",
            searchList:[],
            startAddress: null,
            endAddress: null,
            tjdAddress: null,
            pointInfo: null,
            tjd: [],
            pointType: 1,
            pmHeight: 500,
            addressInfo: null,
            isAdd: 'ADD'
        };
    },
    mounted() {
        this.isAdd = this.$route.query.isAdd
        this.isLoading = false;
        this.pmHeight = 600 - 400;
        document.title = "新增闲置";
        if(this.$route.query.isAdd == 'edit') {
            this.getPassengerTripList();
        }
    },
    methods: {
        getPassengerTripList() {
            this.isShowLoading = true;
            let params = {
                id: this.$route.query.id
            };
            goods.getGoodsInfo(params).then((res) => {
                this.goodsId = res.data.data.id
                this.goodsUnit = res.data.data.goodsUnit
                this.goodsName = res.data.data.goodsName
                this.goodsPrice= res.data.data.goodsPrice
                this.goodsNum= res.data.data.goodsNum
                this.goodsCategory= res.data.data.goodsCategory
                let ls = (res.data.data.goodsPic).split(',')
                for(let i in ls) {
                    this.jyjcbgList.push(
                                Config.resource.base + ls[i]
                            );
                    this.jyjcbgStringList.push(ls[i]);
                }     
                this.isShowLoading = false;
            });
        },
        formatRealName() {
            this.cName = this.deleteNum(this.cName);
        },
        getImgList(e) {
            let rtList = [];
            let imgs = e.split(",");
            for (let i in imgs) {
                rtList.push(Config.resource.base + imgs[i]);
            }
            return rtList;
        },
        getImgList1(e) {
            let rtList = [];
            let imgs = e.split(",");
            for (let i in imgs) {
                rtList.push(imgs[i]);
            }
            return rtList;
        },
  
        submitOrEditData() {
          if(this.isAdd == 'ADD') {
              this.addTrip()
          } else {
              this.editTrip()
          }
        },
        addTrip() {
          if(this.goodsName == '') {
              Toast.fail('请输入名称');
              return
          }
          if(this.goodsPrice == '') {
              Toast.fail('请输入价格');
              return
          }
          if(this.goodsNum == '') {
              Toast.fail('请输入数量');
              return
          }
          if(this.jyjcbgStringList.length == 0) {
              Toast.fail('请上传实物图片');
              return
          }
          this.isLoading = true
          let params = {
            goodsName: this.goodsName,
            goodsPrice: this.goodsPrice,
            goodsNum: this.goodsNum,
            goodsPic: this.jyjcbgStringList.toString(),
            goodsUnit: this.goodsUnit,
            goodsCategory: this.goodsCategory
          }
          goods.addGoods(params).then(res=>{
            if(res.data.code == 200) {
              this.isLoading = false
              Dialog.alert({
                  title: 'Hi',
                  message: '发布成功~',
                  }).then(() => {
                      this.$router.back()
                  });
            }
          })
        },
        editTrip() {
            if(this.goodsName == '') {
              Toast.fail('请输入名称');
              return
          }
          if(this.goodsPrice == '') {
              Toast.fail('请输入价格');
              return
          }
          if(this.goodsNum == '') {
              Toast.fail('请输入数量');
              return
          }
          if(this.jyjcbgStringList.length == 0) {
              Toast.fail('请上传实物图片');
              return
          }
          this.isLoading = true
          let params = {
              id: this.goodsId,
              goodsName: this.goodsName,
              goodsPrice: this.goodsPrice,
              goodsNum: this.goodsNum,
              goodsPic: this.jyjcbgStringList.toString(),
              goodsUnit: this.goodsUnit,
              goodsCategory: this.goodsCategory
          }  
          console.log(params,2221)
          goods.updateGoods(params).then(res=>{
            if(res.data.code == 200) {
              this.isLoading = false
              Dialog.alert({
                  title: 'Hi',
                  message: '修改成功~',
                  }).then(() => {
                      this.$router.back()
                  });
            }
          })
        },
        upLoadImg(e) {
            let file = e.target.files[0];
            this.beforeRead(file)
        },
        uploadImg(fd) {
            // let params = {
            //     file: fd,
            //     biz: 'temp'
            // };
            passenger.upLoadFile(fd)
                .then((res) => {
                    this.jyjcbgList.push(
                        Config.resource.base + res.data.key
                    );
                    this.jyjcbgStringList.push(res.data.key);
                    this.isLoading = false;
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },
        getQueryString: function (name) {
            let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
            let r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(decodeURI(r[2]));
            }
        },
        delPic(index) {
            this.jyjcbgList.splice(index, 1);
            this.jyjcbgStringList.splice(index, 1);
        },
        showPic(index) {
            ImagePreview(this.jyjcbgList, index);
        },
        beforeRead(file) {
          this.isLoading = true;
          // 上传之前校验
          return new Promise((resolve, reject) => {
            console.log(reject) // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
            console.log('压缩前', file) // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
            imageConversion.compressAccurately(file, 100).then(res => { // console.log(res)
              res = new File([res], file.name, { type: res.type, lastModified: Date.now() })
              console.log('压缩后', res)
              let fd = new FormData();
              fd.append("pic", res);
              this.uploadImg(fd);
              resolve(res)
            })
          })
        }
    },
  };
  </script>
  <style scoped>
  .titles {
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 30px;
      font-weight: 600;
  }
  /deep/ .van-calendar__confirm {
    height: 45px !important;
    font-size: 16px;
  }
  .zzgh {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgba(255, 255, 255, 0.9);
      height: 200px;
      text-align: center;
      line-height: 200px;
      font-size: 18px;
  }
  .search-box1 {
    /* position: absolute;
    left: 0;
    top:0; */
    width: 100%;
    display: flex;
    background: #fff;
    height: 60px;
    align-items: center;
    padding: 0 15px;
      z-index: 99999999999;
      justify-content: space-between;
  }
  .search-box {
    position: fixed;
    left: 0;
    bottom: 0px;
    width: 100%;
    display: flex;
    background: #fff;
    min-height: 60px;
    flex-direction: column;
    /* align-items: center; */
    z-index: 99999999999;
  }
  .search-box-one {
    position: relative;
    width: 85%;
    /* margin-left: 5%; */
    height: 40px;
    border: 2px solid #f2f2f2;
    border-radius: 40px;
    display: flex;
    align-items: center;
  }
  .input-sty {
    width: 80%;
    margin-left: 2%;
    height: 35px;
    line-height: 35px;
    border: none;
  }
  .search-icon {
    font-size: 20px;
    margin-left: 10px;
  }
  .info-box {
    width: 92%;
    margin-left: 4%;
    padding: 5px 0 10px 0;
  }
  input {
    padding: 0;
  }
  textarea {
    padding: 0;
  }
  .box-margin {
    width: 94%;
    margin-left: 3%;
    position: relative;
    top: -30px;
  }
  .bg-title {
    width: 100%;
  }
  .loading {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .x-box1 .img-list:nth-child(4) {
    margin-right: 0;
  }
  
  .x-box1 .img-list:nth-child(8) {
    margin-right: 0;
  }
  .img-list {
    position: relative;
    width: 23%;
    height: 77px;
    margin-bottom: 8px;
    margin-right: 2%;
  }
  .dele {
    width: 22px;
    height: 22px;
    position: absolute;
    top: -7px;
    right: -7px;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
  }
  .add-img1 {
    position: relative;
    width: 23%;
    display: flex;
    align-items: center;
    height: 77px;
    margin-bottom: 7px;
  }
  
  .file {
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #1e88c7;
    text-decoration: none;
    text-indent: 0;
    line-height: 20px;
    width: 100%;
    height: 87px;
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .file input {
    width: 100%;
    height: 87px;
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
  }
  
  .file:hover {
    color: #004974;
    text-decoration: none;
  }
  
  .img-size1 {
    width: 20px;
    height: 20px;
  }
  .box {
    height: 100%;
    position: relative;
    background: #eef7f8;
  }
  .bar {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .box-item:last-child {
    border-bottom: none;
  }
  .share-url {
    width: 92%;
    margin-left: 4%;
    height: 49px;
    background: #079bab;
    line-height: 49px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    border-radius: 5px;
    margin-top: 15px;
    cursor: pointer;
  }
  .share-url1 {
    width: 92%;
    margin-left: 4%;
    height: 49px;
    background: #f2f2f2;
    line-height: 49px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    border-radius: 5px;
    margin-top: 15px;
    cursor: pointer;
  }
  .content-box {
    width: 100%;
    /* margin-top: 9px; */
    background: #fff;
    padding-bottom: 15px;
    border-radius: 10px;
  }
  .box-item {
    height: 77px;
    width: 92%;
    margin-left: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #f2f2f2;
  }
  .box-item2 {
    height: 121px;
    width: 92%;
    margin-left: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #f2f2f2;
  }
  .box-item3 {
    /* height: 121px; */
    width: 92%;
    margin-left: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border-bottom: 1px solid #f2f2f2; */
  }
  .box-item4 {
    /* height: 121px; */
    width: 92%;
    margin-left: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border-bottom: 2px solid #f2f2f2; */
    background: rgba(236, 165, 48, 0.16);
    padding: 10px;
    border-radius: 5px;
    color: #eca530;
    box-sizing: border-box;
  }
  .box-item6 {
    /* height: 121px; */
    position: absolute;
    left: 0;
    top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border-bottom: 2px solid #f2f2f2; */
    background: rgba(236, 165, 48, 0.76);
    padding: 5px 10px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    color: #fff;
  }
  .box-item3 > div:nth-child(1) {
    font-size: 14px;
    color: #4b4d4d;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .box-item3 > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .box-item2 > div:nth-child(1) {
    font-size: 14px;
    color: #4b4d4d;
  }
  .box-item2 > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .box-item > div:nth-child(1) {
    font-size: 16px;
    color: #4b4d4d;
  }
  .box-item > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .box-item1 {
    height: 77px;
    width: 92%;
    margin-left: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #f2f2f2;
  }
  .box-item1 > div:nth-child(1) {
    font-size: 16px;
    color: #4b4d4d;
  }
  .box-item1 > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 8px;
  }
  .btm-icon {
    width: 12px;
  }
  .title {
    font-size: 15px;
    color: #c0c0c0;
    margin-top: 8px;
  }
  .user-type {
    width: 30%;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
  }
  .redbtn-icon {
    width: 18px;
    margin-right: 8px;
  }
  .input-gh {
    width: 100%;
    border: none;
    margin-top: 8px;
    font-size: 15px;
  }
  .input-xq {
    width: 100%;
    border: none;
    margin-top: 8px;
    height: 66px;
    font-size: 15px;
    resize: none;
  }
  input::placeholder {
    color: #c0c0c0;
  }
  textarea::placeholder {
    color: #c0c0c0;
  }
  .list-item {
    margin-bottom: 10px;
  }
  .leftbtn {
    display: flex;
    align-items: center;
  }
  .gzxqd {
    height: 26.5px;
    border: 1px solid #079bab;
    color: #079bab;
    line-height: 26.5px;
    font-size: 12px;
    text-align: center;
    padding: 0 10px;
    border-radius: 26px;
  }
  .gdt {
      width:90%;margin-left:5%;overflow-y: scroll;
  }
  .gdt::-webkit-scrollbar {
      display: none; /* Chrome Safari */
  }
  .address-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #f2f2f2;
  }
  .next {
    width:100%;
    height: 39px;
    background: #2D83E8;
    margin-top: 20px;;
    border-radius: 40px;
    text-align: center;
    line-height: 39px;
    color: #fff;
    font-size: 16px;;
    margin-bottom: 10px;;
  }
  </style>
  